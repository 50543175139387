import { Button, Checkbox, Form, Input, Spin, Statistic, Typography, message } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
//@ts-ignore
import styles from './styles.module.scss';
import { RightOutlined } from '@ant-design/icons';
import { messageError, messageSuccess } from "../../components/Message";
import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import { useDispatch } from "react-redux";
import { loginUser, resetPassword, resetToken, sendReset } from "../../reducers/user";
import Cookies from 'js-cookie';
import { authSetIsAuthenticated } from "../../reducers/auth";

const { Title } = Typography;
const { Countdown } = Statistic;

export default function LoginPage({ isAuthEvent, setIsAuthEvent }) {

  const deadline = Number(localStorage.getItem('count_ts'));

  // const dateDeadline = +deadline

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [remember, setRemember] = useState(false);
  const [secureToken, setSecureToken] = useState("");
  const [secureEmail, setSecureEmail] = useState("");
  const [isFinished, setIsFinished] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const buttonRef = useRef(null);

  const location = useLocation();
  const isRecoverForm = location.hash.includes('recover')
  const isResetForm = location.hash.includes('reset')

  useEffect(() => {
    if(isResetForm) {
      const recoveryArray = location.hash.split('&')
      const [token, userEmail]: [string, string] = [recoveryArray.find((item) => item.startsWith('token'))?.replace("token=", ""), recoveryArray.find((item) => item.startsWith('email'))?.replace("email=", "")] as [string, string]
      setSecureToken(token)
      setSecureEmail(userEmail)

      dispatch(resetToken(userEmail, token))
      .then((res) => {
        setIsTokenValid(true)
      })
      .catch((error) => {
        messageError(error)
        setIsTokenValid(false)
      })
    }
  }, [isResetForm])

  
  const handleRememberUser = useCallback((e) => {
    setRemember(e.target.checked)
  }, []);

  const handleSubmit = () => {
    setIsProcessing(true);
    dispatch(loginUser(username, password))
      //@ts-ignore
      .then((res: any) => {
      if (typeof res === "string" ? res.includes('423') : res.status === 423) {
        messageError('Failed to authenticate! Please, verify your email!')
        setIsProcessing(false);
      }
      else if (typeof res === "string" ? res.includes('500') : res.status === 500) {
        messageError('Failed to authenticate! Something happened on our end. Please, be patient, we are working on it!')
        setIsProcessing(false);
      } else {
        if (!res.error) {
        const { name, token, referrer } = res
        const expirationTime = new Date(new Date().getTime() + 30 * 60 * 1000);
        Cookies.set('auth_token', token, { expires: remember ? 7 : expirationTime });
        localStorage.setItem('username', name)
        localStorage.setItem('referrer', referrer)
        //@ts-ignore
        dispatch(authSetIsAuthenticated(true))
        setIsProcessing(false);
        navigate('/')
      }
    }
      })
      .catch((error) => {
        error?.includes("UNAUTHORIZED") ? messageError("Failed to authenticate! Invalid login or password") : error?.includes("database") ? messageError(`Failed to authenticate! Something happened on our end. Please, be patient, we are working on it!`) : messageError(`Failed to authenticate! ${error}`);
        setIsProcessing(false);
      })
  };

  const handleRecoverPassword = () => {
    const usernameStandartized = username.toLowerCase()
    setIsProcessing(true)
    dispatch(sendReset(usernameStandartized))
    //@ts-ignore
      .then((res) => {
        messageSuccess(`Recovery link has been sent to ${username}`);
        localStorage.setItem('count_ts', (Date.now() + 60 * 1000).toString())
        setIsFinished(false);
        setIsProcessing(false);
        
      })
      .catch((error) => {
        messageError(error);
        setIsProcessing(false);
      })
  }

  const handleResetPassword = () => {
    setIsProcessing(true);
    dispatch(resetPassword(secureEmail, password, secureToken))
    //@ts-ignore
      .then((res) => {
        messageSuccess("Successfully changed password!");
        setIsProcessing(false);
        navigate('/sign-in')
      })
      .catch((error) => {
        messageError(error);
        setIsProcessing(false);
      })
  }

  const handleFinishTimer = () => {
    setIsFinished(true)
  }

  return (
    <div className={styles.registerForm}>
      <div className={styles.header}>
      <h1>{isResetForm ? 'Set new password' : isRecoverForm ? 'Password reset' : 'Log in'}</h1>
      {!isResetForm && <a className={styles.textLink} href={isRecoverForm ? '/sign-in' : '/signup'}>{isRecoverForm ? 'Cancel' : 'Register'}</a>}
      </div>
    {isResetForm ? 
    <Form onFinish={handleResetPassword}>
      <Form.Item 
      name="newPassword" 
      rules={[{ required: true, message: 'Please input your new password!' }]}
      >
        <Input
          autoComplete="off"
          type="password"
          id="newPassword"
          placeholder="New password"
          className={styles.registerInput}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item 
      name="confirm" 
      rules={[
        { 
          required: true, 
          message: 'Please confirm your new password!' 
        }, 
        ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("newPassword") === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Passwords mismatch!'));
        },
      }),]}
      dependencies={["newPassword"]}
      >
        <Input
          autoComplete="off"
          type="password"
          placeholder="Confirm new password"
          className={styles.registerInput}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Form.Item>
      <Button
          aria-label="Submit password button"
          htmlType="submit"
          type="primary"
          disabled={isProcessing || !isTokenValid}
          className={`${styles.button}`}
          ref={buttonRef}
          >
            {isProcessing ? 'Resetting password...' : 'Reset'}
          </Button> 
          </Form.Item>
    </Form>
    : <Form onFinish={isRecoverForm ? handleRecoverPassword : handleSubmit}>
      <Form.Item name="username" rules={[{ required: true, message: 'Please input your email!' }]}>
        <Input
          type="email"
          id="username"
          placeholder="Email"
          className={styles.registerInput}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        </Form.Item>
        {!isRecoverForm && <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
        <Input
          
          type="password"
          id="password"
          placeholder="Password"
          className={styles.registerInput}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        </Form.Item>}
        {errorMsg && <p>{errorMsg}</p>}
        <Form.Item>
        {!isRecoverForm && <div className={styles.text}>
         <div><Checkbox className={styles.checkbox} onChange={handleRememberUser}/> Remember me</div>
          <div className={styles.link}><a href={`/sign-in?account#recover`}>Forgot password?</a>
          </div>
          </div>}
        <Button
          aria-label="Log In Button"
          htmlType="submit"
          type="primary"
          disabled={isProcessing || !isFinished}
          className={`${styles.button}`}
          ref={buttonRef}
          >
            
            {isRecoverForm ? isProcessing ? 'Sending email...' : !isFinished ? <Countdown value={deadline} format="mm:ss" onFinish={handleFinishTimer}/> : 'Recover' : isProcessing ? 'Signing in...' : 'Log In'}
          </Button> 
          </Form.Item>
    </Form>}
    </div>
  );
}


